@tailwind base;
@tailwind components;
@tailwind utilities;

.summary {
  bottom: 5rem;
  right: 43rem;
}

.hide-scroller::-webkit-scrollbar{
  display: none;
}

.text-xxs{
  font-size: 0.65rem;
}

.active{
  background-image: url('../src//assets/images/backgroundImg.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 3px;
}
